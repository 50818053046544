import { db } from "../main.js";
import { firestoreAction } from "vuexfire";
import firebase from "firebase/app";

const companyShortlists = {
  namespaced: true,
  state: {
    shortlists: [],
    selectedShortlist: null,
    editingName: null,
    companyId: null,
    products: {},
    loading: false,
    init: false,
    filter: "all",
  },
  mutations: {
    setSelectedShortlist: (state, payload) => {
      state.selectedShortlist = payload;
    },
    setEditingName: (state, payload) => {
      state.editingName = payload;
    },
    setLoading: (state, payload) => {
      state.loading = payload;
    },
    setProducts: (state, payload) => {
      state.products = payload;
    },
    setCompanyId: (state, payload) => {
      state.companyId = payload;
    },
    setInit: (state, payload) => {
      state.init = payload;
    },
    setFilter: (state, payload) => {
      state.filter = payload;
    },
  },
  getters: {
    getCompanyShortlistById: (state) => (id) => {
      return state.shortlists.find((list) => list.id === id);
    },
    currentShortlists:
      (state, getters, rootState, rootGetters) => (searchString) => {
        let shortlists = state.shortlists;

        if (state.filter !== "all") {
          shortlists = shortlists.filter((shortlist) => {
            const accountOwner = rootGetters[
              "companies/getCompanyAccountOwnerByVismaId"
            ](shortlist.companyId);

            if (accountOwner === state.filter) {
              return true;
            } else {
              return false;
            }
          });
        }

        if (searchString === "") {
          return shortlists;
        } else {
          return shortlists.filter((list) => {
            if (
              list.name === undefined ||
              list.name === null ||
              rootGetters["settings/getCompanyNameByVismaId"](
                list.companyId
              ) === undefined
            ) {
              console.log(list.id);
              return false;
            }

            return (
              list.name.toLowerCase().includes(searchString.toLowerCase()) ||
              rootGetters["settings/getCompanyNameByVismaId"](list.companyId)
                .toLowerCase()
                .includes(searchString.toLowerCase())
            );
          });
        }
      },
    selectedShortlistData: (state) => {
      if (state.selectedShortlist === null) {
        return null;
      }
      return state.shortlists.find(
        (list) => list.id === state.selectedShortlist
      );
    },
    sortedProducts: (state, getters, rootState, rootGetters) => {
      let tempProducts = [];
      Object.keys(state.products).forEach((key) => {
        if (rootGetters["products/getProductByFirebaseId"](key) === undefined) {
          console.log("Deleted: " + key);
        } else {
          tempProducts.push(
            rootGetters["products/getProductByFirebaseId"](key)
          );
        }
      });

      let newProducts = {};
      tempProducts
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((product) => {
          newProducts[product.firebaseId] = true;
          return;
        });

      return newProducts;
    },
    sortedProductsWithData: (state, getters, rootState, rootGetters) => {
      let newProducts = [];
      Object.keys(getters.sortedProducts).forEach((key) => {
        newProducts.push(rootGetters["products/getProductByFirebaseId"](key));
      });

      return newProducts;
    },
  },
  actions: {
    bindShortlists: firestoreAction(async (context) => {
      console.log("start dispatch");
      await new Promise((resolve) => setTimeout(resolve, 500));
      return context.bindFirestoreRef(
        "shortlists",
        db
          .collection("shortlists")
          .where("type", "==", "company")
          .orderBy("updated", "desc")
      );
    }),
    unbindShortlists: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef("shortlists");
    }),
    createShortlist: firestoreAction((context) => {
      return db
        .collection("shortlists")
        .add({
          name: "New Shortlist",
          products: {},
          companyId: null,
          type: "company",
        })
        .then((doc) => {
          context.commit("setSelectedShortlist", doc.id);
          return context.dispatch("setupEditing").then(() => {
            return;
          });
        });
    }),
    deleteShortlist: firestoreAction((context) => {
      const deleteId = context.state.selectedShortlist;
      // Make sure we are using the companies on shortlist from last save,
      // Ignore any potential change since that hasn't been applied yet.

      const companyId = context.getters.selectedShortlistData.companyId;

      // Delete shortlist from company
      let p1 = new Promise((resolve) => {
        if (companyId !== null) {
          const company =
            context.rootGetters["companies/getCompanyByVismaId"](companyId);

          if (company !== undefined) {
            db.collection("companies")
              .doc(company.id)
              .update({
                ["shortlists." + deleteId]:
                  firebase.firestore.FieldValue.delete(),
              })
              .then(() => {
                resolve();
              });
          } else {
            resolve();
          }
        } else {
          resolve();
        }
      });

      // Delete shortlist
      let p2 = new Promise((resolve) => {
        db.collection("shortlists")
          .doc(deleteId)
          .delete()
          .then(() => {
            resolve();
          });
      });

      return Promise.all([p1, p2]).then(() => {
        return context.dispatch("exitEditing").then(() => {
          return;
        });
      });
    }),
    saveShortlist: firestoreAction((context) => {
      const shortlistId = context.state.selectedShortlist;
      const oldCompanyId = context.getters.selectedShortlistData.companyId;
      const newCompanyId = context.state.companyId;
      const name = context.state.editingName;
      const products = context.getters.sortedProducts;

      /* eslint-disable */

      // Delete shortlist from company, if needed.
      const p1 = new Promise((resolve) => {
        if (newCompanyId !== oldCompanyId && oldCompanyId !== null) {
          const company =
            context.rootGetters["companies/getCompanyByVismaId"](oldCompanyId);

          if (company !== undefined) {
            db.collection("companies")
              .doc(company.id)
              .update({
                ["shortlists." + shortlistId]:
                  firebase.firestore.FieldValue.delete(),
              })
              .then(() => {
                resolve();
              });
          } else {
            resolve();
          }
        } else {
          resolve();
        }
      });

      // Do changes on shortlist
      const p2 = new Promise((resolve) => {
        db.collection("shortlists")
          .doc(shortlistId)
          .update({
            name: name,
            products: products,
            companyId: newCompanyId,
            type: "company",
          })
          .then(() => {
            resolve();
          });
      });

      // Update company object
      const p3 = new Promise((resolve) => {
        // Update all companies.
        if (newCompanyId !== null) {
          const company =
            context.rootGetters["companies/getCompanyByVismaId"](newCompanyId);

          if (company !== undefined) {
            const companyShortlistData = {
              name: name,
              type: "company",
            };

            db.collection("companies")
              .doc(company.id)
              .update({
                ["shortlists." + shortlistId]: companyShortlistData,
              })
              .then(() => {
                resolve();
              });
          } else {
            resolve();
          }
        } else {
          resolve();
        }
      });

      return Promise.all([p1, p2, p3]).then(() => {
        return context.dispatch("exitEditing").then(() => {
          return;
        });
      });
    }),
    setupEditing: (context) => {
      context.commit(
        "setEditingName",
        context.getters.selectedShortlistData.name
      );
      context.commit(
        "setCompanyId",
        context.getters.selectedShortlistData.companyId
      );
      context.commit(
        "setProducts",
        context.getters.selectedShortlistData.products
      );
    },
    exitEditing: (context) => {
      context.commit("setSelectedShortlist", null);
      context.commit("setEditingName", null);
      context.commit("setCompanyId", null);
      context.commit("setProducts", {});
    },
  },
};
export default companyShortlists;
