<template>
  <div class="chat-new-room-selector">
    <ChatSearchFilter />
    <simplebar class="chat-new-rooms-list" data-simplebar-auto-hide="false">
      <div
        v-for="room in sortedFilteredChatRooms"
        class="chat-new-room-card"
        :class="{ active: selectedChatRoom === room.id }"
        :key="room.id"
        @click="selectChatRoom(room)"
      >
        <div class="chat-new-room-card-icon">
          <span class="material-icons">{{
            room.type == "personal" ? "face" : "storefront"
          }}</span>
          <span
            v-if="notifications[room.id] !== undefined"
            class="chat-new-notification-badge"
            :class="{ active: room.accountOwner == userVismaId }"
          >
            {{ notifications[room.id].count }}
          </span>
        </div>
        <div class="chat-new-room-card-content">
          <div class="chat-new-room-content-top">
            <div class="chat-new-room-content-top-name" :class="room.type">
              {{ room.name }}
              <span v-if="room.type == 'personal'">{{ room.companyName }}</span>
            </div>
            <div class="chat-new-room-content-top-timestamp">
              {{
                room.timestamp == null
                  ? ""
                  : room.timestamp.toDate().toLocaleTimeString("en-GB", {
                      hour: "2-digit",
                      minute: "2-digit",
                    })
              }}
            </div>
          </div>
          <div class="chat-new-room-content-bottom">
            <p>{{ room.message }}</p>
          </div>
        </div>
      </div>
    </simplebar>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
// let audio = new Audio(require('@/assets/message.mp3'))

import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";

export default {
  name: "ChatRoomSelector",
  data() {
    return {
      search: "",
      timeout: null,
    };
  },
  components: {
    simplebar,
  },
  computed: {
    ...mapState({
      userId: (state) => state.settings.user.id,
      selectedChatRoom: (state) => state.chat.selectedChatRoom,
      notifications: (state) => state.chat.notifications,
      userVismaId: (state) => state.settings.user.vismaId,
    }),
    ...mapGetters("chat", [
      "sortedFilteredChatRooms",
      "indexOfFirstIncomingMessage",
      "chatMessageByIndex",
    ]),
    ...mapGetters("users", ["getUserNameById", "getUserCompanyById"]),
  },
  methods: {
    debounceSearch: function () {
      if (this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        // your action
        this.search = this.$refs.chatSearch.value;
      }, 500); // delay
    },
    selectChatRoom: function (room) {
      console.log(room.id);
      if (this.selectedChatRoom === room.id) {
        return false;
      }

      if (this.$refs.chatMessage) {
        this.$refs.chatMessage.value = "";
      }

      this.files = [];

      this.$store.dispatch("chat/bindChatRoom", room.id).then(() => {
        if (room.accountOwner == this.userVismaId) {
          if (this.indexOfFirstIncomingMessage !== -1) {
            const chatMessage = this.chatMessageByIndex(
              this.indexOfFirstIncomingMessage
            );
            if (
              chatMessage.readBy === undefined ||
              chatMessage.readBy.length === 0
            ) {
              this.$store
                .dispatch("chat/markMessageAsRead", {
                  chatRoomId: this.selectedChatRoom,
                  messageId: chatMessage.id,
                  name: this.getUserNameById(this.userId),
                })
                .then(() => {
                  console.log("done");
                });
            }
          }

          this.$store.dispatch("chat/removeNotification", room.id).then(() => {
            console.log("Cleared notification");
          });
        }
      });
    },
  },
};
</script>
