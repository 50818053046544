<template>
  <div class="upsell-companies">
    <div class="admin-table">
      <div class="table-header">
        <span class="label name">Name</span>
        <span class="label list-cart">Cart list</span>
        <span class="label list-home">Home lists</span>
      </div>
      <div class="inner">
        <div
          v-for="company in companiesWithUpsellSorting"
          :key="company.id"
          :class="{ active: currentlySelectedCompanies.includes(company.id) }"
          @click="companySelect(company.id)"
        >
          <span class="name">{{ company.name }}</span>
          <span
            class="list-cart"
            :class="{
              'no-list': getUpsellListNameById(company.upsell) === 'No list',
            }"
            >{{ getUpsellListNameById(company.upsell) }}</span
          >

          <span class="list-home" v-if="company.homeUpsell.length > 0">
            <span
              v-for="list in company.homeUpsell"
              :key="list"
              :class="{
                'no-list': getUpsellListNameById(list) === 'No list',
              }"
              >{{ getUpsellListNameById(list) }}

              <span
                class="home-list-delete"
                @click="deleteHomeList(list, company.id)"
                ><div class="close">X</div></span
              >
            </span>
          </span>
          <span class="list-home no-lists" v-else> No lists </span>
        </div>
      </div>
    </div>
    <div class="upsell-company-selection-buttons">
      <button @click="clearSelection">Clear selection</button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "UpsellCompanies",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      loading: (state) => state.cartUpsell.loading,
      selectedCompanies: (state) => state.cartUpsell.selectedCompanies,
      excludedCompanies: (state) => state.cartUpsell.excludedCompanies,
    }),
    ...mapGetters("companies", ["companiesWithUpsellSorting"]),
    ...mapGetters("cartUpsell", [
      "getUpsellListNameById",
      "currentlySelectedCompanies",
    ]),
  },
  components: {},
  methods: {
    clearSelection: function () {
      this.$store.dispatch("cartUpsell/clearSelections");
    },
    deleteHomeList: function (listId, companyId) {
      let confirm = window.confirm(
        "Remove list from company?\nThis can not be undone."
      );
      if (confirm) {
        this.$store.dispatch("cartUpsell/deleteHomeList", {
          listId: listId,
          companyId: companyId,
        });
      }
    },
    companySelect: function (id) {
      if (this.currentlySelectedCompanies.includes(id)) {
        if (this.selectedCompanies.includes(id)) {
          this.$store.dispatch("cartUpsell/updateSelection", {
            type: "remove",
            field: "Companies",
            value: id,
          });
        } else {
          // Exclude
          this.$store.dispatch("cartUpsell/updateExclusion", {
            type: "add",
            field: "Companies",
            value: id,
          });
        }
      } else {
        if (this.excludedCompanies.includes(id)) {
          // Exclude
          this.$store.dispatch("cartUpsell/updateExclusion", {
            type: "remove",
            field: "Companies",
            value: id,
          });
        } else {
          // Include
          this.$store.dispatch("cartUpsell/updateSelection", {
            type: "add",
            field: "Companies",
            value: id,
          });
        }
      }
    },
  },
};
</script>
