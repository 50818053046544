import { db } from "../main.js";
// import { firestoreAction } from 'vuexfire'
import firebase from "firebase/app";

const massEditShortlists = {
  namespaced: true,
  state: {
    selectedProduct: null,
    excludedCompanyShortlists: [],
    excludedSharedShortlists: [],
    loading: false,
    replace: false,
    replaceProduct: null,
    init: false,
  },
  mutations: {
    setLoading: (state, payload) => {
      state.loading = payload;
    },
    setSelectedProduct: (state, payload) => {
      state.selectedProduct = payload;
    },
    setExcludedCompanyShortlists: (state, payload) => {
      state.excludedCompanyShortlists = payload;
    },
    setExcludedSharedShortlists: (state, payload) => {
      state.excludedSharedShortlists = payload;
    },
    setReplace: (state, payload) => {
      state.replace = payload;
    },
    setReplaceProduct: (state, payload) => {
      state.replaceProduct = payload;
    },
    setInit: (state, payload) => {
      state.init = payload;
    },
  },
  getters: {
    selectedCompanyShortlists: (state, getters, rootState) => {
      return rootState.companyShortlists.shortlists
        .filter((shortlist) => {
          return Object.keys(shortlist.products).find(
            (id) => id === state.selectedProduct
          );
        })
        .filter(
          (shortlist) =>
            state.excludedCompanyShortlists.indexOf(shortlist.id) === -1
        );
    },
    selectedSharedShortlists: (state, getters, rootState) => {
      return rootState.sharedShortlists.shortlists
        .filter((shortlist) => {
          return Object.keys(shortlist.products).find(
            (id) => id === state.selectedProduct
          );
        })
        .filter(
          (shortlist) =>
            state.excludedSharedShortlists.indexOf(shortlist.id) === -1
        );
    },
    getCompanyShortlistNameById: (state, getters, rootState) => (id) => {
      return rootState.companyShortlists.shortlists.find(
        (shortlist) => shortlist.id === id
      ).name;
    },
    getCompanyShortlistCompanyIdById: (state, getters, rootState) => (id) => {
      return rootState.companyShortlists.shortlists.find(
        (shortlist) => shortlist.id === id
      ).companyId;
    },
    getSharedShortlistNameInternalById: (state, getters, rootState) => (id) => {
      return rootState.sharedShortlists.shortlists.find(
        (shortlist) => shortlist.id === id
      ).nameInternal;
    },
  },
  actions: {
    replaceProduct: (context) => {
      let shortlistIds = [];
      const removeProductId = context.state.selectedProduct;
      const addProductId = context.state.replaceProduct;

      context.getters.selectedSharedShortlists.map((list) => {
        shortlistIds.push(list.id);
        return list;
      });

      context.getters.selectedCompanyShortlists.map((list) => {
        shortlistIds.push(list.id);
        return list;
      });

      let updatePromises = shortlistIds.map((id) => {
        return new Promise((resolve) => {
          db.collection("shortlists")
            .doc(id)
            .update({
              ["products." + removeProductId]:
                firebase.firestore.FieldValue.delete(),
              ["products." + addProductId]: true,
            })
            .then(() => {
              resolve();
            });
        });
      });

      return Promise.all(updatePromises).then(() => {
        return context.dispatch("exitEditing").then(() => {
          console.log("finished updating");
          return {
            amount: shortlistIds.length,
            productRemoved: removeProductId,
            productAdded: addProductId,
          };
        });
      });
    },
    deleteProduct: (context) => {
      let shortlistIds = [];
      const productId = context.state.selectedProduct;

      context.getters.selectedSharedShortlists.map((list) => {
        shortlistIds.push(list.id);
        return list;
      });

      context.getters.selectedCompanyShortlists.map((list) => {
        shortlistIds.push(list.id);
        return list;
      });

      let deletePromises = shortlistIds.map((id) => {
        return new Promise((resolve) => {
          db.collection("shortlists")
            .doc(id)
            .update({
              ["products." + productId]: firebase.firestore.FieldValue.delete(),
            })
            .then(() => {
              resolve();
            });
        });
      });

      return Promise.all(deletePromises).then(() => {
        return context.dispatch("exitEditing").then(() => {
          console.log("finished deleteing");
          return {
            amount: shortlistIds.length,
            product: productId,
          };
        });
      });
    },
    setupEditing: (context) => {
      console.log(context);
    },
    exitEditing: (context) => {
      console.log(context);
      context.commit("setSelectedProduct", null);
      context.commit("setExcludedCompanyShortlists", []);
      context.commit("setExcludedSharedShortlists", []);
      context.commit("setReplace", false);
      context.commit("setReplaceProduct", null);
    },
    updateExclusion: function (context, payload) {
      const type = payload.type;
      const field = payload.field;
      const value = payload.value;

      let newSelection = context.state["excluded" + field + "Shortlists"];

      // Do array action.
      if (type === "add") {
        newSelection = newSelection
          .filter((val) => val !== value)
          .concat(value);
      } else if (type === "remove") {
        newSelection = newSelection.filter((val) => val !== value);
      }

      context.commit("setExcluded" + field + "Shortlists", newSelection);
    },
  },
};
export default massEditShortlists;
