import { db } from "../main.js";
import { firestoreAction } from "vuexfire";
import firebase from "firebase/app";

const sharedShortlists = {
  namespaced: true,
  state: {
    shortlists: [],
    selectedShortlist: null,
    editingName: null,
    editingNameInternal: null,
    products: {},
    currentStep: 0, // 0 = Filter companies, 1 = Products
    loading: false,
    selectedCompanyCategories: [],
    selectedPriceGroups1: [],
    selectedPriceGroups2: [],
    selectedPriceGroups3: [],
    selectedCompanies: [],
    selectedExclusion: [],
    init: false,
    filter: "all",
  },
  mutations: {
    setSelectedShortlist: (state, payload) => {
      state.selectedShortlist = payload;
    },
    setEditingName: (state, payload) => {
      state.editingName = payload;
    },
    setEditingNameInternal: (state, payload) => {
      state.editingNameInternal = payload;
    },
    setLoading: (state, payload) => {
      state.loading = payload;
    },
    setCurrentStep: (state, payload) => {
      state.currentStep = payload;
    },
    setSelectedCompanyCategories: (state, payload) => {
      state.selectedCompanyCategories = payload;
    },
    setSelectedPriceGroups1: (state, payload) => {
      state.selectedPriceGroups1 = payload;
    },
    setSelectedPriceGroups2: (state, payload) => {
      state.selectedPriceGroups2 = payload;
    },
    setSelectedPriceGroups3: (state, payload) => {
      state.selectedPriceGroups3 = payload;
    },
    setSelectedCompanies: (state, payload) => {
      state.selectedCompanies = payload;
    },
    setProducts: (state, payload) => {
      state.products = payload;
    },
    setSelectedExclusion: (state, payload) => {
      state.selectedExclusion = payload;
    },
    setInit: (state, payload) => {
      state.init = payload;
    },
    setFilter: (state, payload) => {
      state.filter = payload;
    },
  },
  getters: {
    getSharedShortlistById: (state) => (id) => {
      return state.shortlists.find((list) => list.id === id);
    },
    currentShortlists: (state) => (searchString) => {
      let shortlists;
      if (state.filter === "all") {
        shortlists = state.shortlists;
      } else {
        shortlists = state.shortlists.filter(
          (list) => list.author.firebaseId === state.filter
        );
      }

      if (searchString === "") {
        return shortlists;
      } else {
        if (searchString.includes("@")) {
          return shortlists.filter((list) =>
            list.author.email.toLowerCase().includes(searchString.toLowerCase())
          );
        } else {
          return shortlists.filter((list) => {
            if (list.nameInternal == null) {
              console.log(list);
            }
            return list.nameInternal
              .toLowerCase()
              .includes(searchString.toLowerCase());
          });
        }
      }
    },
    selectedShortlistData: (state) => {
      if (state.selectedShortlist === null) {
        return null;
      }
      return state.shortlists.find(
        (list) => list.id === state.selectedShortlist
      );
    },
    finalCompanies: (state, getters, rootState) => {
      let companies = rootState.companies.companies;
      return companies
        .filter((company) => {
          if (
            state.selectedCompanyCategories.length > 0 &&
            state.selectedCompanyCategories.indexOf(
              company.companyCategory.toString()
            ) !== -1
          ) {
            return true;
          }

          if (
            state.selectedPriceGroups1.length > 0 &&
            state.selectedPriceGroups1.indexOf(
              company.companyPriceGroup.toString()
            ) !== -1
          ) {
            return true;
          }

          if (
            state.selectedPriceGroups2.length > 0 &&
            state.selectedPriceGroups2.indexOf(
              company.companyPriceGroup2.toString()
            ) !== -1
          ) {
            return true;
          }

          if (
            state.selectedPriceGroups3.length > 0 &&
            state.selectedPriceGroups3.indexOf(
              company.companyPriceGroup3.toString()
            ) !== -1
          ) {
            return true;
          }

          if (
            state.selectedCompanies.length > 0 &&
            state.selectedCompanies.indexOf(company.vismaId) !== -1
          ) {
            return true;
          }

          return false;
        })
        .filter((company) => {
          if (
            state.selectedExclusion.length > 0 &&
            state.selectedExclusion.indexOf(company.vismaId) !== -1
          ) {
            return false;
          }
          return true;
        })
        .map((company) => {
          return {
            vismaId: company.vismaId,
            firebaseId: company.id,
          };
        });
    },
    sortedProducts: (state, getters, rootState, rootGetters) => {
      let tempProducts = [];
      Object.keys(state.products).forEach((key) => {
        if (rootGetters["products/getProductByFirebaseId"](key) === undefined) {
          console.log("Deleted: " + key);
        } else {
          tempProducts.push(
            rootGetters["products/getProductByFirebaseId"](key)
          );
        }
      });

      let newProducts = {};
      tempProducts
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((product) => {
          newProducts[product.firebaseId] = true;
          return;
        });

      return newProducts;
    },
    sortedProductsWithData: (state, getters, rootState, rootGetters) => {
      let newProducts = [];
      Object.keys(getters.sortedProducts).forEach((key) => {
        newProducts.push(rootGetters["products/getProductByFirebaseId"](key));
      });

      return newProducts;
    },
  },
  actions: {
    bindShortlists: firestoreAction(async (context) => {
      await new Promise((resolve) => setTimeout(resolve, 500));
      return context.bindFirestoreRef(
        "shortlists",
        db
          .collection("shortlists")
          .where("type", "==", "shared")
          .orderBy("updated", "desc")
      );
    }),
    unbindShortlists: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef("shortlists");
    }),
    createShortlist: firestoreAction(async (context) => {
      return db
        .collection("shortlists")
        .add({
          name: "New Shortlist",
          nameInternal: "Internal Shortlist Name",
          products: {},
          companies: [],
          selections: {
            selectedCompanyCategories: [],
            selectedPriceGroups1: [],
            selectedPriceGroups2: [],
            selectedPriceGroups3: [],
            selectedCompanies: [],
            selectedExclusion: [],
          },
          author: {
            email: context.rootState.settings.user.email,
            firebaseId: context.rootState.settings.user.uid,
            vismaId: context.rootState.settings.user.vismaId,
          },
          type: "shared",
        })
        .then((doc) => {
          console.log("created");
          context.commit("setSelectedShortlist", doc.id);
          return context.dispatch("setupEditing").then(() => {
            return;
          });
        });
    }),
    deleteShortlist: firestoreAction((context) => {
      console.log(context.getters.finalCompanies);

      const deleteId = context.state.selectedShortlist;

      return db
        .collection("shortlists")
        .doc(deleteId)
        .delete()
        .then(() => {
          return context.dispatch("exitEditing").then(() => {
            return;
          });
        });
    }),
    saveShortlist: firestoreAction((context) => {
      // Find if any company has been removed from selection.
      let deletedCompanies = [];

      context.getters.selectedShortlistData.companies.map((company) => {
        let found = context.getters.finalCompanies.find(
          (finalCompany) => finalCompany.firebaseId === company.firebaseId
        );

        if (found === undefined) {
          deletedCompanies.push(company.firebaseId);
        }

        return company;
      });

      return db
        .collection("shortlists")
        .doc(context.state.selectedShortlist)
        .update({
          name: context.state.editingName,
          nameInternal: context.state.editingNameInternal,
          products: context.getters.sortedProducts,
          companies: context.getters.finalCompanies,
          selections: {
            selectedCompanyCategories: context.state.selectedCompanyCategories,
            selectedPriceGroups1: context.state.selectedPriceGroups1,
            selectedPriceGroups2: context.state.selectedPriceGroups2,
            selectedPriceGroups3: context.state.selectedPriceGroups3,
            selectedCompanies: context.state.selectedCompanies,
            selectedExclusion: context.state.selectedExclusion,
          },
          type: "shared",
        })
        .then(() => {
          // // Update all companies.
          // let companyShortlistData = {
          //   name: context.state.editingName,
          //   type: "shared",
          // };

          // let companyPromises = context.getters.finalCompanies.map(
          //   (company) => {
          //     return new Promise((resolve) => {
          //       db.collection("companies")
          //         .doc(company.firebaseId)
          //         .update({
          //           ["shortlists." + context.state.selectedShortlist]:
          //             companyShortlistData,
          //         })
          //         .then(() => {
          //           resolve();
          //         });
          //     });
          //   }
          // );

          if (deletedCompanies.length) {
            let deletePromises = deletedCompanies.map((company) => {
              return new Promise((resolve) => {
                db.collection("companies")
                  .doc(company)
                  .update({
                    ["shortlists." + context.state.selectedShortlist]:
                      firebase.firestore.FieldValue.delete(),
                  })
                  .then(() => {
                    resolve();
                  });
              });
            });

            return Promise.all(deletePromises).then(() => {
              return context.dispatch("exitEditing").then(() => {
                return;
              });
            });
          } else {
            return context.dispatch("exitEditing").then(() => {
              return;
            });
          }
        });
    }),
    setupEditing: (context) => {
      context.commit("setCurrentStep", 0);
      context.commit(
        "setEditingName",
        context.getters.selectedShortlistData.name
      );
      context.commit(
        "setEditingNameInternal",
        context.getters.selectedShortlistData.nameInternal
      );
      context.commit(
        "setProducts",
        context.getters.selectedShortlistData.products
      );
      context.commit(
        "setSelectedCompanyCategories",
        context.getters.selectedShortlistData.selections
          .selectedCompanyCategories
      );
      context.commit(
        "setSelectedPriceGroups1",
        context.getters.selectedShortlistData.selections.selectedPriceGroups1
      );
      context.commit(
        "setSelectedPriceGroups2",
        context.getters.selectedShortlistData.selections.selectedPriceGroups2
      );
      context.commit(
        "setSelectedPriceGroups3",
        context.getters.selectedShortlistData.selections.selectedPriceGroups3
      );
      context.commit(
        "setSelectedCompanies",
        context.getters.selectedShortlistData.selections.selectedCompanies
      );
      context.commit(
        "setSelectedExclusion",
        context.getters.selectedShortlistData.selections.selectedExclusion
      );
    },
    exitEditing: (context) => {
      context.commit("setSelectedShortlist", null);
      context.commit("setEditingName", null);
      context.commit("setEditingNameInternal", null);
      context.commit("setProducts", {});
      context.commit("setSelectedCompanyCategories", []);
      context.commit("setSelectedPriceGroups1", []);
      context.commit("setSelectedPriceGroups2", []);
      context.commit("setSelectedPriceGroups3", []);
      context.commit("setSelectedCompanies", []);
      context.commit("setSelectedExclusion", []);
      context.commit("setCurrentStep", 0);
    },
    updateSelection: function (context, payload) {
      const type = payload.type;
      const field = payload.field;
      const value = payload.value;

      let newSelection = context.state["selected" + field];

      // Do array action.
      if (type === "add") {
        newSelection = newSelection
          .filter((val) => val !== value)
          .concat(value);
      } else if (type === "remove") {
        newSelection = newSelection.filter((val) => val !== value);
      }

      context.commit("setSelected" + field, newSelection);
    },
  },
};
export default sharedShortlists;
