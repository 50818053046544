import { db } from "./../main.js";
import { firestoreAction } from "vuexfire";
import firebase from "firebase/app";

const settings = {
  namespaced: true,
  state: {
    init: false,
    appSettings: null,
    user: null,
    maintenance: null,
    companyList: null,
    productSettings: null,
    noCreditMessage: null,
    taxonomies: null,
    unavailableDates: [],
    generalHelpers: {},
    appHelpers: {},
  },
  mutations: {
    setInit: (state, payload) => {
      state.init = payload;
    },
    setUnavailableDates: (state, payload) => {
      state.unavailableDates = payload;
    },
  },
  getters: {
    userRoles: (state) => {
      return state.appSettings.userRoles;
    },
    taxonomies: (state) => {
      return state.taxonomies;
    },
    categories: (state) => {
      return state.taxonomies.categories;
    },
    productCategories: (state) => {
      return state.productSettings.categoriesTranslated;
    },
    extraProductCategories: (state) => {
      return state.productSettings.extraCategories;
    },
    categoryGroupsTranslated: (state) => {
      return state.productSettings.categoryGroupsTranslated;
    },
    priceGroup1: (state) => {
      return state.taxonomies.priceGroup1;
    },
    priceGroup1Name: (state) => {
      return state.taxonomies.priceGroup1.name;
    },
    priceGroup2: (state) => {
      return state.taxonomies.priceGroup2;
    },
    priceGroup2Name: (state) => {
      return state.taxonomies.priceGroup2.name;
    },
    priceGroup3: (state) => {
      return state.taxonomies.priceGroup3;
    },
    priceGroup3Name: (state) => {
      return state.taxonomies.priceGroup3.name;
    },
    companyRoutes: (state) => {
      return state.taxonomies.routes;
    },
    maintenanceData: (state) => {
      return state.maintenance;
    },
    isMaintenance: (state) => {
      return state.appSettings.maintenance;
    },
    deliveryCutOff: (state) => {
      return state.appSettings.deliveryCutOff;
    },
    deliveryBlockedDays: (state) => {
      return state.appSettings.deliveryBlockedDays;
    },
    deliveryStandardTime: (state) => {
      return state.appSettings.deliveryStandardTime;
    },
    displayProductImages: (state) => {
      return state.appSettings.displayProductImages;
    },
    appLanguages: (state) => {
      return state.appSettings.languages;
    },
    getCategoryById: (state) => (id) => {
      if (state.taxonomies.categories[id] === undefined) {
        return "Not found, ID: " + id;
      } else {
        return state.taxonomies.categories[id];
      }
    },
    getPriceGroupById: (state) => (id) => {
      if (state.taxonomies.priceGroup1[id] === undefined) {
        return "Not found, ID: " + id;
      } else {
        return state.taxonomies.priceGroup1[id];
      }
    },
    getPriceGroup2ById: (state) => (id) => {
      if (state.taxonomies.priceGroup2[id] === undefined) {
        return "Not found, ID: " + id;
      } else {
        return state.taxonomies.priceGroup2[id];
      }
    },
    getPriceGroup3ById: (state) => (id) => {
      if (state.taxonomies.priceGroup3[id] === undefined) {
        return "Not found, ID: " + id;
      } else {
        return state.taxonomies.priceGroup3[id];
      }
    },
    getRouteById: (state) => (id) => {
      if (state.taxonomies.routes[id] === undefined) {
        return "Not found, ID: " + id;
      } else {
        return state.taxonomies.routes[id];
      }
    },
    noCreditMessage: (state) => {
      return state.appSettings.noCreditMessage;
    },
    userRolesSorted: (state, getters) => {
      const obsArr = [];
      for (const role in getters.userRoles) {
        obsArr.push(getters.userRoles[role]);
      }

      return obsArr.sort((a, b) => (a.slug > b.slug ? 1 : -1));
    },
    userRole: (state, getters) => {
      return getters.userRoles[state.user.role];
    },

    getCompanyNameByVismaId: (state) => (id) => {
      if (id === null) {
        return "";
      }
      return state.companyList.companies[id];
    },
    getCompanyListSelectOptions: (state) => {
      let returnArr = [];

      for (const [key, value] of Object.entries(state.companyList.companies)) {
        returnArr.push({
          label: value,
          id: key,
        });
      }

      return returnArr.sort((a, b) => a.label.localeCompare(b.label));
    },
    registerSegmentNameById: (state) => (id) => {
      return state.appSettings.registerSegments.find(
        (segment) => segment.value === id
      ).name;
    },
    getCategoryNameById: (state) => (id) => {
      return state.productSettings.categories[id];
    },
    getExtraCategoryNameById: (state) => (id) => {
      return state.productSettings.extraCategories[id] !== undefined
        ? state.productSettings.extraCategories[id].name
        : "Not found";
    },
    getCategoriesBySearch: (state) => (searchString) => {
      const returnObj = {};
      const search = searchString.trim().toLowerCase();

      if (searchString === "") {
        return state.productSettings.categories;
      }

      for (const [key, value] of Object.entries(
        state.productSettings.categories
      )) {
        if (value.toLowerCase().includes(search)) {
          returnObj[key] = value;
        }
      }

      console.log(returnObj);

      return returnObj;
    },
    getExtraCategoriesBySearch: (state) => (searchString) => {
      const returnObj = {};
      const search = searchString.trim().toLowerCase();

      if (searchString === "") {
        return state.productSettings.extraCategories;
      }

      for (const [key, value] of Object.entries(
        state.productSettings.extraCategories
      )) {
        if (value.name.toLowerCase().includes(search)) {
          returnObj[key] = value;
        }
      }
    },
    getSegmentsBySearch: (state) => (searchString) => {
      const returnObj = {};
      const search = searchString.trim().toLowerCase();

      if (searchString === "") {
        return state.taxonomies.priceGroup2;
      }

      for (const [key, value] of Object.entries(state.taxonomies.priceGroup2)) {
        if (value.toLowerCase().includes(search)) {
          returnObj[key] = value;
        }
      }
    },
    getSegmentNameById: (state) => (id) => {
      return state.taxonomies.priceGroup2[id];
    },
    getCategoryGroupById: (state) => (id) => {
      return state.productSettings.categoryGroups[id];
    },
    getCategoryGroupTranslationById: (state) => (id, lang) => {
      if (
        state.productSettings.categoryGroupsTranslated[lang] === undefined ||
        state.productSettings.categoryGroupsTranslated[lang][id] === undefined
      ) {
        return "No translation found";
      }
      return state.productSettings.categoryGroupsTranslated[lang][id];
    },
    sortedCategoryGroups: (state) => {
      // Sort by createdAt
      const obsArr = [];
      for (const key in state.productSettings.categoryGroups) {
        const data = state.productSettings.categoryGroups[key];
        data.id = key;
        obsArr.push(data);
      }

      return obsArr.sort((a, b) => (b.createdAt > a.createdAt ? 1 : -1));
    },
    sortedCurrencies: (state) => {
      const obsArr = [];
      for (const key in state.appSettings.currencies) {
        const data = state.appSettings.currencies[key];
        data.iso = key;
        obsArr.push(data);
      }

      return obsArr.sort((a, b) => (a.iso > b.iso ? 1 : -1));
    },
  },
  actions: {
    initApp: ({ dispatch, state, commit }, uid) => {
      if (state.init) {
        console.log("Already inited, leaving early");
        return false;
      }

      // User document
      const p1 = new Promise((resolve) => {
        dispatch("bindUser", uid).then(() => {
          if (state.user.role !== "admin" && state.user.role !== "superAdmin") {
            firebase.auth().signOut();
          }
          resolve();
        });
      });

      const p2 = new Promise((resolve) => {
        dispatch("bindCompanyList").then(() => {
          resolve();
        });
      });

      const p3 = new Promise((resolve) => {
        dispatch("products/bindProductList", {}, { root: true }).then(() => {
          resolve();
        });
      });

      // const p4 = new Promise((resolve) => {
      //   dispatch("orders/bindOrders", {}, { root: true }).then(() => {
      //     resolve();
      //   });
      // });

      const p5 = new Promise((resolve) => {
        dispatch("users/bindUserList", {}, { root: true }).then(() => {
          dispatch(
            "users/readPushNotificationSettings",
            {},
            { root: true }
          ).then(() => {
            resolve();
          });
        });
      });

      const p6 = new Promise((resolve) => {
        dispatch("chat/bindChatRooms", {}, { root: true }).then(() => {
          resolve();
        });
      });

      const p7 = new Promise((resolve) => {
        dispatch("companies/bindCompanies", {}, { root: true }).then(() => {
          resolve();
        });
      });

      // // const p8 = new Promise((resolve) => {
      // //   dispatch("chat/bindAdminChatNotifications", {}, { root: true }).then(
      // //     () => {
      // //       resolve();
      // //     }
      // //   );
      // // });

      // const p9 = new Promise((resolve) => {
      //   dispatch("logs/bindProductSyncLog", {}, { root: true }).then(() => {
      //     resolve();
      //   });
      // });

      // const p10 = new Promise((resolve) => {
      //   dispatch("logs/bindAdminUserSyncLog", {}, { root: true }).then(() => {
      //     resolve();
      //   });
      // });

      // const p11 = new Promise((resolve) => {
      //   dispatch("logs/bindUserSyncLog", {}, { root: true }).then(() => {
      //     resolve();
      //   });
      // });

      // const p12 = new Promise((resolve) => {
      //   dispatch("logs/bindCompanySyncLog", {}, { root: true }).then(() => {
      //     resolve();
      //   });
      // });

      // const p13 = new Promise((resolve) => {
      //   dispatch("logs/bindOrderSyncLog", {}, { root: true }).then(() => {
      //     resolve();
      //   });
      // });

      // const p14 = new Promise((resolve) => {
      //   dispatch("notifications/bindPosts", {}, { root: true }).then(() => {
      //     resolve();
      //   });
      // });

      // const p15 = new Promise((resolve) => {
      //   dispatch("sharedShortlists/bindShortlists", {}, { root: true }).then(
      //     () => {
      //       resolve();
      //     }
      //   );
      // });

      // const p16 = new Promise((resolve) => {
      //   dispatch("companyShortlists/bindShortlists", {}, { root: true }).then(
      //     () => {
      //       resolve();
      //     }
      //   );
      // });

      const p17 = new Promise((resolve) => {
        dispatch("logs/bindPriceListSyncLog", {}, { root: true }).then(() => {
          resolve();
        });
      });

      const p18 = new Promise((resolve) => {
        dispatch("logs/bindPriceListRemoveLog", {}, { root: true }).then(() => {
          resolve();
        });
      });

      // const p19 = new Promise((resolve) => {
      //   dispatch("priceLists/bindPriceLists", {}, { root: true }).then(() => {
      //     resolve();
      //   });
      // });
      const p20 = new Promise((resolve) => {
        dispatch("settings/bindTaxonomies", {}, { root: true }).then(() => {
          resolve();
        });
      });
      const p21 = new Promise((resolve) => {
        dispatch("bindMaintenance").then(() => {
          resolve();
        });
      });
      const p22 = new Promise((resolve) => {
        dispatch("bindProductSettings").then(() => {
          resolve();
        });
      });

      // const p23 = new Promise((resolve) => {
      //   dispatch("notifications/bindTemplates", {}, { root: true }).then(() => {
      //     resolve();
      //   });
      // });

      const p24 = new Promise((resolve) => {
        dispatch("languages/bindTranslationData", {}, { root: true }).then(
          () => {
            resolve();
          }
        );
      });

      const p25 = new Promise((resolve) => {
        dispatch("languages/bindFieldsDoc", {}, { root: true }).then(() => {
          resolve();
        });
      });

      const p26 = new Promise((resolve) => {
        dispatch("analytics/bindAnalyticsDocs", {}, { root: true }).then(() => {
          resolve();
        });
      });

      // const p27 = new Promise((resolve) => {
      //   dispatch("logs/bindPriceListNewSyncLog", {}, { root: true }).then(
      //     () => {
      //       resolve();
      //     }
      //   );
      // });

      const p28 = new Promise((resolve) => {
        dispatch("orders/bindOfflineOrders", {}, { root: true }).then(() => {
          resolve();
        });
      });

      // const p29 = new Promise((resolve) => {
      //   dispatch("cartUpsell/bindUpsellLists", {}, { root: true }).then(() => {
      //     resolve();
      //   });
      // });

      const p30 = new Promise((resolve) => {
        dispatch("bindGeneralHelpers").then(() => {
          resolve();
        });
      });

      // const p31 = new Promise((resolve) => {
      //   dispatch("chat/bindChatRooms", {}, { root: true }).then(() => {
      //     resolve();
      //   });
      // });

      const p32 = new Promise((resolve) => {
        dispatch("chat/bindAdminChatNotifications", {}, { root: true }).then(
          () => {
            resolve();
          }
        );
      });

      const p33 = new Promise((resolve) => {
        dispatch("bindAppHelpers").then(() => {
          resolve();
        });
      });

      // Show app
      return Promise.all([
        p1,
        p2,
        p3,
        // p4,
        p5,
        p6,
        p7,
        // // p8,
        // p9,
        // p10,
        // p11,
        // p12,
        // p13,
        // p14,
        // p15,
        // p16,
        p17,
        p18,
        // p19,
        p20,
        p21,
        p22,
        // p23,
        p24,
        p25,
        p26,
        // p27,
        p28,
        // p29,
        p30,
        // p31,
        p32,
        p33,
      ]).then(() => {
        commit("setInit", true);
      });
    },
    clearApp: ({ dispatch, commit }) => {
      commit("setInit", false);
      dispatch("unbindUser");
    },
    bindAppSettings: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef(
        "appSettings",
        db.collection("app").doc("settings")
      );
    }),
    bindTaxonomies: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef(
        "taxonomies",
        db.collection("app").doc("taxonomies")
      );
    }),
    bindMaintenance: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef(
        "maintenance",
        db.collection("app").doc("maintenance")
      );
    }),
    bindProductSettings: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef(
        "productSettings",
        db.collection("app").doc("productSettings")
      );
    }),
    bindGeneralHelpers: firestoreAction((context) => {
      return context.bindFirestoreRef(
        "generalHelpers",
        db.collection("app").doc("generalHelpers")
      );
    }),
    bindAppHelpers: firestoreAction((context) => {
      return context.bindFirestoreRef(
        "appHelpers",
        db.collection("app").doc("appHelpers")
      );
    }),
    bindUser: firestoreAction(({ bindFirestoreRef }, uid) => {
      return bindFirestoreRef("user", db.collection("users").doc(uid));
    }),
    unbindUser: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef("user");
    }),
    updateUserRole: firestoreAction((context, data) => {
      // return the promise so we can await the write
      return db.collection("users").doc(data.uid).set(
        {
          role: data.role,
        },
        { merge: true }
      );
    }),
    updateUserRoleName: firestoreAction((context, data) => {
      return db
        .collection("app")
        .doc("settings")
        .update({
          ["userRoles." + data.role + ".name"]: data.name,
        });
    }),
    updateAppSettingsOption: firestoreAction((context, data) => {
      return db
        .collection("app")
        .doc("settings")
        .update({
          [data.field]: data.value,
        });
    }),
    bindCompanyList: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef(
        "companyList",
        db.collection("app").doc("companyList")
      );
    }),
    updateMaintenance: firestoreAction((context, payload) => {
      return db
        .collection("app")
        .doc("settings")
        .update({ maintenance: payload });
    }),
    setMaintenanceMessage: firestoreAction((context, payload) => {
      return db
        .collection("app")
        .doc("maintenance")
        .update({ message: payload });
    }),
    setMaintenanceTitle: firestoreAction((context, payload) => {
      return db.collection("app").doc("maintenance").update({ title: payload });
    }),
    updateCustomerPriceGroup: firestoreAction((context, payload) => {
      const field = payload.field;
      const key = payload.key;
      const value = payload.value;
      console.log("field:", field, "key:", key, "value:", value);

      return db
        .collection("app")
        .doc("taxonomies")
        .update({
          [field + "." + key]: value,
        })
        .then(() => {
          return true;
        });
    }),
    updatePriceGroupName: firestoreAction((context, payload) => {
      const { newName, target } = payload;

      return db
        .collection("app")
        .doc("taxonomies")
        .update({ [target + ".name"]: newName })
        .then(() => {
          return true;
        });
    }),
    updateNoCreditMessage: firestoreAction((context, payload) => {
      console.log(payload);
      return db
        .collection("app")
        .doc("settings")
        .update({
          noCreditMessage: payload,
        })
        .then(() => {
          return true;
        });
    }),
    updateDisplayProductImages: firestoreAction((context, payload) => {
      return db
        .collection("app")
        .doc("settings")
        .update({
          displayProductImages: payload,
        })
        .then(() => {
          return true;
        });
    }),
    deleteCustomerPriceGroup: firestoreAction((context, payload) => {
      const field = payload.field;
      const key = payload.key;

      return db
        .collection("app")
        .doc("taxonomies")
        .update({
          [field + "." + key]: firebase.firestore.FieldValue.delete(),
        })
        .then(() => {
          return true;
        });
    }),
    updateProductCategory: firestoreAction((context, payload) => {
      const labels = payload.labels;

      // Backwards compability.
      const p1 = new Promise((resolve) => {
        db.collection("app")
          .doc("productSettings")
          .update({
            ["categories." + payload.id]: labels["en"],
          })
          .then(() => {
            resolve();
          });
      });

      const p2 = new Promise((resolve) => {
        let updatePromises = Object.keys(labels).map((lang) => {
          return new Promise((resolve) => {
            db.collection("app")
              .doc("productSettings")
              .update({
                ["categoriesTranslated." + lang + "." + payload.id]:
                  labels[lang],
              })
              .then(() => {
                resolve();
              });
          });
        });

        Promise.all(updatePromises).then(() => {
          resolve();
        });
      });

      return Promise.all([p1, p2]).then(() => {
        return;
      });
    }),
    updateExtraProductCategory: firestoreAction((context, payload) => {
      const name = payload.name;
      const products = payload.products;
      const id = payload.id;

      // Backwards compability.
      const p1 = new Promise((resolve) => {
        db.collection("app")
          .doc("productSettings")
          .update({
            ["extraCategories." + id]: {
              products: products,
              name: name,
            },
          })
          .then(() => {
            resolve();
          });
      });

      return Promise.all([p1]).then(() => {
        return;
      });
    }),
    deleteProductCategory: firestoreAction((context, data) => {
      const p1 = new Promise((resolve) => {
        return db
          .collection("app")
          .doc("productSettings")
          .update({
            ["categories." + data.id]: firebase.firestore.FieldValue.delete(),
          })
          .then(() => {
            resolve();
          });
      });

      const languages = context.rootGetters["languages/enabledLanguages"];

      const p2 = new Promise((resolve) => {
        let updatePromises = languages.map((lang) => {
          return new Promise((resolve) => {
            db.collection("app")
              .doc("productSettings")
              .update({
                ["categoriesTranslated." + lang.code + "." + data.id]:
                  firebase.firestore.FieldValue.delete(),
              })
              .then(() => {
                resolve();
              });
          });
        });

        Promise.all(updatePromises).then(() => {
          resolve();
        });
      });

      return Promise.all([p1, p2]).then(() => {
        return;
      });
    }),
    deleteExtraProductCategory: firestoreAction((context, payload) => {
      const id = payload.id;
      const p1 = new Promise((resolve) => {
        return db
          .collection("app")
          .doc("productSettings")
          .update({
            ["extraCategories." + id]: firebase.firestore.FieldValue.delete(),
          })
          .then(() => {
            resolve();
          });
      });

      return Promise.all([p1]).then(() => {
        return;
      });
    }),
    updateUnavailableDates: firestoreAction((context, payload) => {
      return db
        .collection("app")
        .doc("settings")
        .update({
          unavailableDates: payload,
        })
        .then(() => {
          context.commit("setUnavailableDates", payload);
        });
    }),
    updateDeliveryCutOff: firestoreAction((context, payload) => {
      return db
        .collection("app")
        .doc("settings")
        .update({
          ["deliveryCutOff." + payload.fieldKey]: payload.fieldData,
        })
        .then(() => {
          console.log("update done");
        });
    }),
    updateDeliveryBlockedDays: firestoreAction((context, payload) => {
      return db
        .collection("app")
        .doc("settings")
        .update({
          deliveryBlockedDays: payload,
        })
        .then(() => {
          console.log("update done");
        });
    }),
    deleteDeliveryCutOff: firestoreAction((context, payload) => {
      return db
        .collection("app")
        .doc("settings")
        .update({
          ["deliveryCutOff." + payload]: firebase.firestore.FieldValue.delete(),
        })
        .then(() => {
          return true;
        });
    }),
    updateStandardDeliveryTime: firestoreAction((context, payload) => {
      return db
        .collection("app")
        .doc("settings")
        .update({
          ["deliveryStandardTime.hours"]: payload.hours,
          ["deliveryStandardTime.minutes"]: payload.minutes,
        })
        .then(() => {
          console.log("update done");
        });
    }),
    updateStandardDeliverySameDay: firestoreAction((context, payload) => {
      return db
        .collection("app")
        .doc("settings")
        .update({
          ["deliveryStandardTime.sameDay"]: payload,
        })
        .then(() => {
          console.log("update done");
        });
    }),
    updateCurrencyStatus: firestoreAction(async (context, payload) => {
      // If a currency is set to inactive, we need to update all users that have that currency set.
      if (payload.value === false) {
        const usersSnap = await db
          .collection("users")
          .where("currency", "==", payload.iso)
          .get();
        if (usersSnap.docs.length > 0) {
          await Promise.all(
            usersSnap.docs.map(async (userDoc) => {
              await db.collection("users").doc(userDoc.id).update({
                currency: context.state.appSettings.currency,
              });
            })
          );
        }
      }

      return db
        .collection("app")
        .doc("settings")
        .update({
          ["currencies." + payload.iso + ".active"]: payload.value,
        })
        .then(() => {
          console.log("update done");
        });
    }),
  },
};
export default settings;
