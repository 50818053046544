<template>
  <div class="chat-search-filter">
    <div class="chat-new-filter">
      <div
        class="chat-new-filter-check"
        @click="$store.commit('chat/setShowCompany', !showCompany)"
      >
        <div class="check" :class="{ active: showCompany }"></div>
        <label>Company</label>
      </div>

      <div
        class="chat-new-filter-check"
        @click="$store.commit('chat/setShowPersonal', !showPersonal)"
      >
        <div class="check" :class="{ active: showPersonal }"></div>
        <label>Personal</label>
      </div>
    </div>
    <div class="chat-new-sidebar-search">
      <span class="material-icons">search</span>
      <input
        @input="debounceSearch"
        type="search"
        ref="chatSearch"
        placeholder="Search company or user..."
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapGetters } from "vuex";
// let audio = new Audio(require('@/assets/message.mp3'))

import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";

export default {
  name: "chat",
  data() {
    return {
      timeout: null,
    };
  },
  components: {
    simplebar,
  },
  computed: {
    ...mapState({
      showCompany: (state) => state.chat.showCompany,
      showPersonal: (state) => state.chat.showPersonal,
      search: (state) => state.chat.search,
    }),
    ...mapGetters("chat", []),
  },
  mounted() {
    this.$refs.chatSearch.value = this.search;
  },
  methods: {
    debounceSearch: function () {
      if (this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        // your action
        this.$store.commit(
          "chat/setSearch",
          this.$refs.chatSearch.value.trim()
        );
      }, 500); // delay
    },
  },
};
</script>
