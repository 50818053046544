import { db } from "../main.js";
import { firestoreAction } from "vuexfire";
import firebase from "firebase/app";
import "firebase/storage";

import { nanoid } from "nanoid";
// import { v4 as uuidv4 } from 'uuid';

/* eslint-disable */
const chat = {
  namespaced: true,
  state: {
    chatRooms: [],
    selectedChatAdmin: "all",
    selectedChatRoom: false,
    chatRoomMessages: [],
    notifications: {},
    lightboxImage: null,
    search: "",
    showCompany: true,
    showPersonal: true,
  },
  mutations: {
    setSelectedChatAdmin: (state, payload) => {
      state.selectedChatAdmin = payload;
    },
    setSelectedChatRoom: (state, payload) => {
      state.selectedChatRoom = payload;
    },
    setLightboxImage: (state, payload) => {
      state.lightboxImage = payload;
    },
    setSearch: (state, payload) => {
      state.search = payload;
    },
    setShowCompany: (state, payload) => {
      state.showCompany = payload;
    },
    setShowPersonal: (state, payload) => {
      state.showPersonal = payload;
    },
  },

  getters: {
    indexOfFirstIncomingMessage: (state) => {
      let index = state.chatRoomMessages.findIndex(
        (message) => message.admin === false
      );
      return index;
    },
    indexOfFirstAdminMessage: (state) => {
      let index = state.chatRoomMessages.findIndex(
        (message) => message.admin === true
      );
      return index;
    },
    chatMessageByIndex: (state) => (index) => {
      return state.chatRoomMessages[index];
    },
    selectedChatRoomData: (state, getters) => {
      return getters.combinedChatRooms.find(
        (room) => room.id === state.selectedChatRoom
      );
    },
    getAccountOwner: (state, getters) => (roomId) => {
      let room = getters.combinedChatRooms.find((room) => room.id === roomId);
      if (room === undefined) {
        return 0;
      }
      return room.accountOwner;
    },
    combinedChatRooms: (state) => {
      let rooms = [];
      console.log(state.chatRooms);
      for (const [key, value] of Object.entries(state.chatRooms.company)) {
        rooms.push(value);
      }
      for (const [key, value] of Object.entries(state.chatRooms.personal)) {
        rooms.push(value);
      }

      return rooms;
    },
    getShortlistName:
      (state, getters, rootState, rootGetters) => (shortlistId) => {
        let companyId;

        if (getters.selectedChatRoomData.type == "company") {
          companyId = getters.selectedChatRoomData.identifier;
        } else {
          companyId = rootGetters["users/getUserCompanyById"](
            getters.selectedChatRoomData.identifier
          );
        }

        const company = rootGetters["companies/getCompanyByVismaId"](companyId);

        if (company.shortlists[shortlistId] !== undefined) {
          return company.shortlists[shortlistId].name;
        } else {
          return "Unavailable shortlist";
        }
      },
    sortedFilteredChatRooms: (state, getters) => {
      let rooms = [];

      if (state.showPersonal) {
        for (const [key, value] of Object.entries(state.chatRooms.personal)) {
          if (value.name == undefined) {
            console.log(key);
            console.log(value);
          }
          rooms.push(value);
        }
      }

      if (state.showCompany) {
        for (const [key, value] of Object.entries(state.chatRooms.company)) {
          if (value.name == undefined) {
            console.log(key);
          }
          rooms.push(value);
        }
      }

      if (state.selectedChatAdmin !== "all") {
        rooms = rooms.filter(
          (room) => room.accountOwner === state.selectedChatAdmin
        );
      }

      if (state.search !== "") {
        rooms = rooms.filter((room) =>
          room.name.toLowerCase().includes(state.search.toLowerCase())
        );
      }

      rooms.map((room) => {
        if (room == undefined || room.name == undefined) {
          console.log(room);
        }
      });

      rooms.sort((a, b) => a.name.localeCompare(b.name, "sv"));

      rooms.sort((a, b) => {
        if (a.timestamp === null) {
          return 1;
        } else if (b.timestamp === null) {
          return -1;
        } else {
          return b.timestamp - a.timestamp;
        }
      });

      // Put rooms with notifications on top
      rooms.sort((a, b) => {
        if (
          state.notifications[a.id] !== undefined &&
          state.notifications[b.id] === undefined
        ) {
          return -1;
        } else if (
          state.notifications[a.id] === undefined &&
          state.notifications[b.id] !== undefined
        ) {
          return 1;
        } else {
          return 0;
        }
      });

      return rooms;
    },
    selectedAdminCompanyChatRooms:
      (state, getters, rootState) => (searchString) => {
        let chatRooms;
        let returnArr = [];

        // Base chatrooms
        if (state.selectedChatAdmin === "all") {
          chatRooms = getters.companyChatRooms;
        } else {
          chatRooms = getters.companyChatRooms.filter(
            (room) => room.accountOwnerVismaId === state.selectedChatAdmin
          );
        }

        // Search filtering
        if (searchString) {
          chatRooms = chatRooms.filter((room) =>
            room.name.toLowerCase().includes(searchString.toLowerCase())
          );
        }

        // Notifications and userOwner
        chatRooms.forEach((room) => {
          let notifications = 0;
          let currentUserOwner = false;

          if (state.notifications !== null) {
            if (state.notifications[room.id] !== undefined) {
              notifications = state.notifications[room.id].count;
            }
          }

          if (room.accountOwnerVismaId === rootState.settings.user.vismaId) {
            currentUserOwner = true;
          }

          room.notifications = notifications;
          room.currentUserOwner = currentUserOwner;
          returnArr.push(room);
        });

        // Sort first by company name, then by notification count
        return returnArr
          .sort((a, b) => a.name.localeCompare(b.name, "sv"))
          .sort((a, b) => b.notifications - a.notifications);
      },
    selectedAdminPersonalChatRooms:
      (state, getters, rootState) => (searchString) => {
        let chatRooms;
        let returnArr = [];

        if (state.selectedChatAdmin === "all") {
          chatRooms = getters.personalChatRooms;
        } else {
          chatRooms = getters.personalChatRooms.filter(
            (room) => room.accountOwnerVismaId === state.selectedChatAdmin
          );
        }

        // Search filtering
        if (searchString) {
          chatRooms = chatRooms.filter((room) =>
            room.name.toLowerCase().includes(searchString.toLowerCase())
          );
        }

        chatRooms.forEach((room) => {
          let notifications = 0;
          let currentUserOwner = false;

          if (state.notifications !== null) {
            if (state.notifications[room.id] !== undefined) {
              notifications = state.notifications[room.id].count;
            }
          }

          if (room.accountOwnerVismaId === rootState.settings.user.vismaId) {
            currentUserOwner = true;
          }

          room.notifications = notifications;
          room.currentUserOwner = currentUserOwner;

          if (room.name == undefined) {
            console.log("no name:");
            console.log(room.id);
          }
          returnArr.push(room);
        });

        return returnArr
          .sort((a, b) => a.name.localeCompare(b.name))
          .sort((a, b) => b.notifications - a.notifications);
      },
    notificationCountByAdmin: (state, getters) => (admin) => {
      // let personalChatRooms = getters.personalChatRooms.filter(
      //   (room) => room.accountOwnerVismaId === admin
      // );
      // let companyChatRooms = getters.companyChatRooms.filter(
      //   (room) => room.accountOwnerVismaId === admin
      // );

      let total = 0;

      Object.keys(state.notifications).forEach((key) => {
        const chatRoom = getters.combinedChatRooms.find(
          (room) => room.id === key
        );
        if (chatRoom !== undefined) {
          if (chatRoom.accountOwner === admin) {
            total += state.notifications[key].count;
          }
        }
      });
      return total;
    },
    totalNotificationCount: (state) => {
      let total = 0;

      if (!state.notifications) {
        return total;
      }

      Object.keys(state.notifications).forEach((key) => {
        total += state.notifications[key].count;
      });
      return total;
    },
    adminUsersWithChatRooms: (state, getters, rootState, rootGetters) => {
      let admins = rootGetters["users/getAdminUsers"];
      let returnArr = [];

      admins.forEach((admin) => {
        let companyChatRooms = getters.combinedChatRooms.filter(
          (room) => room.accountOwner === admin.vismaId
        );

        if (companyChatRooms.length > 0) {
          returnArr.push(admin);
        }
      });

      return returnArr;
    },
    selectedChatRoomName: (state, getters) => {
      if (!state.selectedChatRoom) {
        return "No room";
      }

      let chatRoom = getters.combinedChatRooms.find(
        (room) => room.id === state.selectedChatRoom
      );
      if (chatRoom === undefined) {
        return "ERROR";
      }

      return chatRoom.name;
    },
    getChatRoomById: (state, getters) => (chatRoomId) => {
      const chatRoom = getters.combinedChatRooms.find((room) => {
        return room.id === chatRoomId;
      });

      if (chatRoom === undefined) {
        return false;
      }
      return chatRoom;
    },
  },
  actions: {
    bindAdminChatNotifications: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef(
        "notifications",
        db.collection("app").doc("adminChatNotifications")
      );
    }),
    bindChatRooms: firestoreAction((context) => {
      return context.bindFirestoreRef(
        "chatRooms",
        db.collection("app").doc("chatRooms")
      );
    }),
    bindChatRoom: firestoreAction((context, payload) => {
      context.commit("setSelectedChatRoom", payload);

      return context.bindFirestoreRef(
        "chatRoomMessages",
        db
          .collection("chatRooms")
          .doc(payload)
          .collection("messages")
          .orderBy("timestamp", "desc")
          .limit(50)
      );
    }),
    sendMessage: firestoreAction((context, payload) => {
      const messageData = {
        message: payload.message,
        timestamp: firebase.firestore.Timestamp.now(),
        author: payload.author,
        authorName: payload.authorName,
        readableTime: payload.readableTime,
        admin: payload.admin,
        image: payload.imageFile ? true : false,
        imageFull: null,
        imageResized: null,
        productId: payload.productId !== false ? payload.productId : null,
        shortlistId: payload.shortlistId,
      };

      return db
        .collection("chatRooms")
        .doc(payload.chatId)
        .collection("messages")
        .add(messageData)
        .then((doc) => {
          console.log(doc.id);

          if (payload.imageFile) {
            const uniqueFileName = nanoid();
            const remoteFullPath =
              "chatRooms/" + payload.chatId + "/" + uniqueFileName;
            const storageRef = firebase.storage().ref().child(remoteFullPath);

            console.log(encodeURIComponent(uniqueFileName));

            console.log(remoteFullPath);

            const metadata = {
              contentType: payload.imageFileType,
              public: true,
              customMetadata: {
                type: "chat",
                messageId: doc.id,
                chatRoomId: payload.chatId,
                authorId: payload.author,
                // firebaseStorageDownloadTokens: uuid,
              },
              // metadata: {
              //     firebaseStorageDownloadTokens: uuid,
              // }
            };

            return storageRef
              .put(payload.imageFile, metadata)
              .then(() => {
                console.log("File upload completed");
              })
              .catch((error) => {
                console.log(error);
                console.log(error.message);
              });
          }
        });
    }),
    removeNotification: firestoreAction((context, payload) => {
      return db
        .collection("app")
        .doc("adminChatNotifications")
        .update({
          [payload]: firebase.firestore.FieldValue.delete(),
        });
    }),
    addNotification: firestoreAction((context, payload) => {
      return db
        .collection("app")
        .doc("adminChatNotifications")
        .update({
          [payload + ".count"]: firebase.firestore.FieldValue.increment(1),
          [payload + ".createdAt"]: firebase.firestore.FieldValue.arrayUnion(
            firebase.firestore.Timestamp.now()
          ),
        });
    }),
    markMessageAsRead: firestoreAction((context, payload) => {
      const chatRoomId = payload.chatRoomId;
      const messageId = payload.messageId;
      const name = payload.name;

      console.log(chatRoomId);
      console.log(messageId);
      console.log(name);

      return db
        .collection("chatRooms")
        .doc(chatRoomId)
        .collection("messages")
        .doc(messageId)
        .update({
          readBy: firebase.firestore.FieldValue.arrayUnion(name),
        })
        .then(() => {
          console.log("Message marked as read");
          return;
        })
        .catch((error) => {
          console.log(error);
          return;
        });
    }),
  },
};
export default chat;
