<template>
  <div class="posts-listing">
    <div class="posts-template-fixed" v-if="renameOpen">
      <div class="template-rename-container">
        <span class="material-icons close-icon" @click="closeRename"
          >close</span
        >
        <h4>Rename template: {{ this.renameName }}</h4>
        <div>
          <input type="text" placeholder="Enter new name" v-model="newName" />
          <button @click="doRename">Save</button>
        </div>
      </div>
    </div>

    <div
      class="posts-check"
      @click="
        $store.commit('notifications/setShowOnlyOwnPosts', !showOnlyOwnPosts)
      "
    >
      <div class="check" :class="{ active: showOnlyOwnPosts }"></div>
      <label>Show only my posts</label>
    </div>

    <h3>Active</h3>

    <div class="admin-table post-list-table">
      <div class="table-header">
        <span class="label created-at">Created</span>
        <span class="label start-date">Release date/time</span>
        <span class="label type">Type</span>
        <span class="label title">Title</span>
        <span class="label author">Author</span>
      </div>

      <div class="inner">
        <div
          v-for="post in activePosts"
          :key="post.id"
          class="single-post"
          @click="edit(post.id)"
        >
          <span class="created-at">{{
            post.createdAt.toDate() | moment("YY-MM-DD")
          }}</span>
          <span class="start-date">{{
            post.startTime.toDate() | moment("YY-MM-DD - HH:ss")
          }}</span>
          <span class="type">{{
            post.type[0].toUpperCase() + post.type.slice(1)
          }}</span>
          <span class="title">{{ post.mainTitle }}</span>
          <span class="author">{{ post.authorName }}</span>
        </div>
        <div class="no-posts" v-if="activePosts.length === 0">
          No active posts found
        </div>
      </div>
    </div>

    <h3>Upcoming</h3>
    <div class="admin-table post-list-table">
      <div class="table-header">
        <span class="label created-at">Created</span>
        <span class="label start-date">Release date/time</span>
        <span class="label type">Type</span>
        <span class="label title">Title</span>
        <span class="label author">Author</span>
      </div>

      <div class="inner">
        <div
          v-for="post in upcomingPosts"
          :key="post.id"
          class="single-post"
          @click="edit(post.id)"
        >
          <span class="created-at">{{
            post.createdAt.toDate() | moment("YY-MM-DD")
          }}</span>
          <span class="start-date">{{
            post.startTime.toDate() | moment("YY-MM-DD - HH:ss")
          }}</span>
          <span class="type">{{
            post.type[0].toUpperCase() + post.type.slice(1)
          }}</span>
          <span class="title">{{ post.mainTitle }}</span>
          <span class="author">{{ post.authorName }}</span>
        </div>
        <div class="no-posts" v-if="upcomingPosts.length === 0">
          No upcoming posts found
        </div>
      </div>
    </div>

    <h3>Drafts</h3>
    <div class="admin-table post-list-table">
      <div class="table-header">
        <span class="label created-at">Created</span>
        <span class="label start-date">Release date/time</span>
        <span class="label type">Type</span>
        <span class="label title">Title</span>
        <span class="label author">Author</span>
      </div>

      <div class="inner">
        <div
          v-for="post in draftPosts"
          :key="post.id"
          class="single-post"
          @click="edit(post.id)"
        >
          <span class="created-at">{{
            post.createdAt.toDate() | moment("YY-MM-DD")
          }}</span>
          <span class="start-date" v-if="post.startTime">{{
            post.startTime.toDate() | moment("YY-MM-DD - HH:ss")
          }}</span>
          <span v-else class="start-date">not set</span>
          <span v-if="post.type" class="type">{{
            post.type[0].toUpperCase() + post.type.slice(1)
          }}</span>
          <span class="type" v-else>(not set)</span>
          <span class="title" v-if="post.mainTitle">{{ post.mainTitle }}</span>
          <span class="title" v-else>(not set)</span>
          <span class="author">{{ post.authorName }}</span>
        </div>
        <div class="no-posts" v-if="draftPosts.length === 0">
          No draft posts found
        </div>
      </div>
    </div>

    <h3>Expired</h3>
    <div class="admin-table post-list-table">
      <div class="table-header">
        <span class="label created-at">Created</span>
        <span class="label start-date">Release date/time</span>
        <span class="label type">Type</span>
        <span class="label title">Title</span>
        <span class="label author">Author</span>
      </div>

      <div class="inner">
        <div
          v-for="post in expiredPosts"
          :key="post.id"
          class="single-post"
          @click="edit(post.id)"
        >
          <span class="created-at">{{
            post.createdAt.toDate() | moment("YY-MM-DD")
          }}</span>
          <span class="start-date">{{
            post.startTime.toDate() | moment("YY-MM-DD - HH:ss")
          }}</span>
          <span class="type">{{
            post.type[0].toUpperCase() + post.type.slice(1)
          }}</span>
          <span class="title">{{ post.mainTitle }}</span>
          <span class="author">{{ post.authorName }}</span>
        </div>
        <div class="no-posts" v-if="expiredPosts.length === 0">
          No expired posts found
        </div>
      </div>
    </div>

    <h3>Templates</h3>
    <div class="admin-table post-list-table">
      <div class="table-header">
        <span class="label title">Title</span>
        <span class="label author">Author</span>
      </div>

      <div class="inner">
        <div
          v-for="post in filteredTemplates"
          :key="post.id"
          class="single-post"
          @click="openTemplate(post.id)"
        >
          <span class="title"
            >{{ post.name }}
            <span class="edit" @click.stop="openRename(post.id, post.name)">
              <span class="material-icons">edit</span>
            </span>
            <span
              class="delete"
              @click.stop="deleteTemplate(post.id, post.name)"
            >
              <span class="material-icons">delete</span>
            </span></span
          >
          <span class="author">{{ post.authorName }} </span>
        </div>
        <div class="no-posts" v-if="filteredTemplates.length === 0">
          No templates found
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "PostsListing",
  data() {
    return {
      renameOpen: false,
      renameId: null,
      renameName: "",
      newName: "",
    };
  },
  computed: {
    ...mapState({
      showOnlyOwnPosts: (state) => state.notifications.showOnlyOwnPosts,
    }),
    ...mapGetters("users", ["getUserNameById", "getCompanyNameByUserId"]),
    ...mapGetters("notifications", [
      "draftPosts",
      "activePosts",
      "expiredPosts",
      "upcomingPosts",
      "filteredTemplates",
      "templateById",
    ]),
  },
  methods: {
    edit: function (id) {
      this.$store.dispatch("notifications/editPost", id);
    },
    deleteTemplate: function (id, name) {
      let confirm = window.confirm(
        "Permanently delete template?\nThis can not be undone."
      );
      if (confirm) {
        this.$store.dispatch("notifications/deleteTemplate", id).then(() => {
          this.$toast.center("Deleted template <span>" + name + "</span>");
        });
      }
    },
    openRename: function (id, name) {
      this.renameOpen = true;
      this.renameId = id;
      this.renameName = name;
      this.newName = "";
    },
    closeRename: function () {
      this.renameOpen = false;
      this.renameId = null;
      this.renameName = "";
      this.newName = "";
    },
    doRename: function () {
      console.log("doRename");
      if (this.newName.trim() === "") {
        return;
      }

      this.$store
        .dispatch("notifications/renameTemplate", {
          id: this.renameId,
          name: this.newName.trim(),
        })
        .then(() => {
          this.$toast.center(
            "Renamed template <span>" +
              this.renameName +
              "</span> to <span>" +
              this.newName.trim() +
              "</span>"
          );
          this.closeRename();
        });
    },
    openTemplate: function (id) {
      console.log(id);

      const template = this.templateById(id);
      console.log(template);

      if (template === undefined) {
        console.log("Cant find template :/");
        return;
      }
      this.$store.dispatch("notifications/clearBuilder").then(() => {
        this.$store.commit("notifications/setBuilder", true);

        this.$store.commit("notifications/setNavigation", "create-edit");
        this.$store.commit("notifications/updateBlocks", template.blocks);
        this.$store.commit("notifications/setImageUrls", template.imageUrls);
        this.$store.commit(
          "notifications/setFeaturedImage",
          template.featuredImage
        );
      });
    },
  },
};
</script>
